
import LazyHydrate from 'vue-lazy-hydration'
import { generateUUID } from '~/utils/generateUUID'
import { mapGetters, mapMutations } from 'vuex'
import RPHCityModal from 'rph/organisms/RPHCityModal/RPHCityModal.vue'

export default {
  name: 'Main',
  components: {
    RPHCityModal,
    LazyHydrate,
    RPHTabBar: () => import('rph/molecules/RPHTabBar/index.vue'),
    RPHToastContainer: () => import('rph/organisms/RPHToastContainer/index.vue'),
    RPHHeader: () => import('rph/templates/RPHHeader/index.vue'),
    RPHHeaderMobile: () => import('rph/templates/RPHHeaderMobile/index.vue'),
    RPHFooter: () => import('rph/templates/RPHFooter/index.vue'),
    RPHModalContainer: () => import('rph/templates/RPHModalContainer/index.vue'),
    RPHAppReminder: () => import('rph/organisms/RPHAppReminder/index.vue'),
    SigninModal: () => import('~/components/organisms/signin/SigninModal.vue'),
  },
  async fetch() {
    if (this.$route.query.formobile && this.$route.query.formobile === 'true') {
      this.$store.commit('rph-main/setIsMobileApp', true)
    }

    if ('authorization' in this.$route.query && !this.isLoggedIn) {
      this.SET_SIGNIN_ACTIVE(true)
      this.SET_SIGNIN_TYPE('auth')
    }
  },
  // fetchOnServer: false,
  async mounted() {
    const viewId = this.generateViewId()
    await this.$store.dispatch('rph-footer/getSettings')
    await this.$store.dispatch('rph-categories/getCategories', { expand: 'child' })
    if (process.client) {
      localStorage.setItem('viewId', viewId)
    }

    if (!localStorage.getItem('cityId') && !this.user.city_id) {
      setTimeout(() => {
        this.$store.commit('rph-contacts/setCityModal', true)
      }, 3000)
    }
  },

  methods: {
    ...mapMutations({
      SET_SIGNIN_ACTIVE: 'signin/SET_SIGNIN_ACTIVE',
      SET_SIGNIN_TYPE: 'signin/SET_SIGNIN_TYPE',
    }),
    generateViewId() {
      const uuid = generateUUID()
      return `${uuid}`
    },
  },
  computed: {
    user() {
      return this.$store.getters['rph-users/user']
    },
    cityModal() {
      return this.$store.getters['rph-contacts/cityModal']
    },
    /* Показывать блок поиска или нет */
    isMainPage() {
      return !(this.$route.path === '/' || !!this.$route.query.pharmacy_id)
    },
    isProductPage() {
      return this.$route.name === 'product-slug'
    },
    isMobileApp() {
      return this.$store.getters['rph-main/isMobileApp']
    },
    isLoggedIn() {
      // @ts-ignore
      return this.$auth.loggedIn
    },
  },
}
